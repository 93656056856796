.container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.contimages{
    width: 96%;
    column-count: 3;
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-width: 33%;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    padding: 12px; 
}

.pics{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}

.pics:hover{
    filter: brightness(0.6);
}

.image {
    width: 100%;
    border-radius:8px;
    padding: 6px;
}

.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(12, 11, 11);
    transition: opacity .4s ease, visibility  .4s ease, transform .5s  ease-in-out ;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open{
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.model img{
    width: auto;
    max-width: 90%;
    height: auto;
    max-height: 90%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}

.model svg{
   position: absolute;
   right: 40px;
   top: 20px;
   width: 40px;
   fill: #fff;
   cursor: pointer;
}

.model svg:hover{
    transition: all 350ms ease;
    transform: scale(1.2);
 }

@media (max-width:991px) {
    .contimages{
        width: 98%;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
   
       
    }
}

@media (max-width:448px) {
    .contimages{
        width: 98%;
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
       
    }
}
