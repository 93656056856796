h2{
    font-size: 50px;
}
p{
    font-size: 20px;
}
.container{
    background-color: #264897;
    background-image: url('../../public/fondo.png') ;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    width: 100vw;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contText{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.contRedes{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.contRedes > div{
    margin: 0 20px;
    cursor: pointer;
}


@media (max-width:550px ) {
    h2{
        font-size: 30px;
    }
    p{
        font-size: 18px;
    }
    .container{
        padding-left: 40px;
        grid-template-columns: 1fr ;
        justify-content: flex-start;
        min-height: 200px;
    }
    .contText{
        display: flex;
        flex-direction: column;
        
        align-items: flex-start;
        color: #fff;
    }
  
    .contRedes {
       justify-content:  flex-start;
    }
    .contRedes > div > svg{
        width: 40px;
        height: 40px;
    }
    
}