button{
    border: none;
    outline: none;
    background: #69B32D;
    cursor: pointer;
    color: #fff;
    padding: 20px 70px;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 40px;
}

button:hover{
    background-color: #56a317;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    padding: 40px 0;
    position: relative;
}

.conTitle{
    width: 85%;
    margin-bottom: 5px;
    position: relative;
    z-index: 1000;
    padding-top: 40px;
    padding-left: 40px;
}

.conTitleDeco{
    background-color: #264897;
    background-image: url('../../public/fondo.png') ;
    background-repeat: no-repeat;
    background-size: cover;
    width: 45%;
    height: 320px;
    position: absolute;
    z-index: 10;
    top: 30px;
    left: 100px;
    border-radius: 10px;
}

.contImages{
    width: 85%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 250px));
    gap: 30px;
    justify-content: center;
    align-items: center;
}

.contImage{
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    position: relative;
}

  

.contImage > img{
    width: 250px;
    height: 250px;
    border-radius: 5px;
    position: relative;
    z-index: 100;
}

.datatitle{
    position: relative;
    z-index: 1000;
}
#logo{
    width: 200px;
    position: absolute;
    right: 60px;
    top: -100px;
}


@media (max-width:800px ) and (min-width:450px) {
    .conTitleDeco{
        left: 70px;
    }
    
}


@media (max-width:550px ) {
    .conTitleDeco{
        left: 30px;
        width: 55%;
        height: 260px;
    }
    .contImages{
        width: 90%;
        grid-template-columns: repeat(auto-fit, minmax(140px, 140px));
        gap: 20px;  
    }
    
    .contImage{
        width: 140px;
        height: 140px;
        padding-left: 10px;
    }
    
    .contImage > img{
        width: 140px;
        height: 140px;
    }
    #logo{
        width: 80px;
        position: absolute;
        right: 20px;
        top: -30px;
    }
    
}