
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 180px 0;
}

.contButton{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #69B32D;
    border-radius:75px;
    color: #fff;
    padding: 0px 70px;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
}

.contButton:hover{

    background-color: #56a317;

}

.container > div{
    background-image: url('../../public/fondo.png') ;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    width:60% ;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.container > div p{
   color: #fff;
   font-size: 40px;
   width: 100%;
   text-align: center;
   font-weight: 800;
}

.contInpusEmail{
    position: absolute;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    bottom: -35px;
    height: 75px;
    box-shadow:  0px 4px 12px rgba(36, 36, 36, 0.25);
    border-radius: 75px;
}

.contInpusEmail input{
   
    height: 100%;
    border-radius: 75px;
    border: none;
    outline: none;
    font-size: 20px;
    padding-left: 25px;

}

@media (max-width:800px ) and (min-width:450px) {
    .container > div{
        width:75% ;
        border-radius: 30px;
    }
    .contInpusEmail{
        height: 65px;
    }
    
    .contInpusEmail input{
        border-radius: 65px;
        font-size: 16px;
    }
}

@media (max-width:550px ) {
    .container{
        padding: 0px 0 180px 0;
    }
    .container > div{
        width:80% ;
        border-radius: 30px;
    }
    .contInpusEmail{
        height: 65px;
        max-width: 80%;
    }
    
    .contInpusEmail input{
        border-radius: 65px;
        font-size: 14px;
        width: 100%;
    }
    .container > div p{
        font-size: 25px; 
     }
     .contButton{
        
        background-color: #69B32D;
        border-radius:75px;
        color: #fff;
        padding: 0px 25px;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
        font-size: 12px;
    }
}