

.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;    
    padding: 0 7%;
    margin: 130px 0 40px 0;
    position: relative;
}

.contCardServices{
    padding: 0px 65px;
    margin: 55px 0;
    display: grid;
    grid-template-columns: 1fr ;
    grid-template-rows: .3fr .2fr 1fr .2fr;
    position: relative;
    z-index: 100;
}

.contCardServices button{
    height: 40px;
    width: 119px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contCardServices h3{
    font-size: 25px;
    margin: 25px 0;
    font-weight: 700;
}

.contCardServices p{
    margin-bottom: 20px;
    font-size: 16px;
}

.contImageCard{
    position: relative;
}

.contImageCard > span{
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    background-color: #D9D9D9;
}

.contImageCard > svg{
    transform: rotateY(-180deg);
}

.contSubtitle{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contSubtitle > span{
    color: #69B32D;
    margin-left: 5px;
    font-weight: 700;
}

.deco{
    position: absolute;
    width: 100vw;
    height: 100%;
    top: -240px;
    z-index: 10;
}

.deco > svg{
    width: 100vw;
    height: 100%;
}

.decoSubtitle{
    height: 4px;
    width: 20px;
    background-color: #69B32D;
}

#avion{
    position: absolute;
    top: -30px;
}

#service1 > span{
    top: -40px;
    left: 55px;
}

#service2 > span{
    top: -40px;
    left: 65px;
}

#service3 > span{
    top: -40px;
    left: 80px;
}

#service4 > span{
    top: -40px;
    left: 115px;
}

#service5 > span{
    top: -40px;
    left: 115px;
}



@media (max-width:950px ) and (min-width:630px) {
    .container {  
        grid-template-columns: 1fr 1fr ;    
        padding: 0 4%;
        margin: 130px 0 40px 0;
        position: relative;
    }
    .contCardServices{
        padding: 0px 45px;
        margin: 45px 0;
    }
    .deco{
        display: none;
    }
    
}

@media (max-width:629px )  {
    .container {
        grid-template-columns: 1fr ;    
        padding: 0 4%;
    }
    .contCardServices{
        padding: 0px 35px;
        margin: 45px 0;
    }
    .deco{
        display: none;
    }
}


