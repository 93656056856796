.container {
    display: grid;
    grid-template-columns: 1fr .9fr;
    margin: 90px 0;
}

.contBtn {
    position: absolute;
    background-color: #69B32D;
    display: flex;
    flex-direction: row;
    top: 60px;
    left: 0px;
    border-radius: 10px;
    z-index: 100000;
}

.contBtn>div {
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.contBtn div:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.contBtn div:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.contBtn>div:hover {
    background-color: #56a317;
}

.contText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contText>div {
    width: 70%;
}


.conTitle {
    margin-bottom: 30px;
}

.conTitle>h2 {
    font-size: 40px;
}

.contImage {
    display: grid;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;
    max-width: 100%;
}

.contImage img {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
}

.contParrafos {
    font-size: 18px;
}

.contParrafos>p {
    margin-bottom: 26px;
}

.contenedorPrincipal{
    overflow: hidden;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    width: 92%;
    margin-left: 8%;
}
.contenedorSlideShow{
    display: flex;
    flex-wrap: nowrap;
    
}
.slide{
    min-width: 100%;
    overflow: hidden;
    max-height: 500px;
    position: relative;  
}

.slide > img{
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
    border-radius: 0;
}

.decoSubTitle {
    height: 4px;
    width: 15px;
    background-color: #69B32D;
    border-radius: 2px;
    margin-right: 5px;
}



.subTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.subTitle>span {
    font-size: 12px;
    color: #69B32D;
    font-weight: 700;
}

@media (max-width:800px) and (min-width:450px) {
    .container {
        grid-template-columns: 1fr;
        margin: 90px 0;
        justify-content: center;
        align-items: center;
    }

    .contImage {
        justify-content: center;
        width: 100%;
        margin-top: 30px;
    }

    .contImage>img {
        border-radius: 40px;
        width: 70%;
    }

    .contBtn {
        left: 60px;
    }

}

@media (max-width:550px) {
    .container {
        grid-template-columns: 1fr;
        margin: 90px 0;
        justify-content: center;
        align-items: center;
    }

    .contImage {
        justify-content: center;
        width: 100%;
        margin-top: 30px;
    }

    .contImage>img {
        border-radius: 40px;
        width: 70%;
        border-radius: 20px;
    }

    .contBtn {
        top: 20px;
        left: 20px;
    }

    .conTitle>h2 {
        font-size: 32px;
    }

    .contParrafos {
        font-size: 16px;
    }

}