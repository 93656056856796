.OptionsSelect {
    background-color: #fff;
    height: 100;
    border-top-left-radius: 70px;
    border-bottom-left-radius: 70px;
    outline:none;
    border: none;
    padding-left: 30px;
    font-size: 16px;
    -webkit-background-color: #fff;
    -webkit-height: 100;
    -webkit-border-top-left-radius: 70px;
    -webkit-border-bottom-left-radius: 70px;
    -webkit-outline:none;
    -webkit-border: none;
    -webkit-padding-left: 30px;
    -webkit-font-size: 16px;
}


.serviseBar {
    position: absolute;
    height: 75px;
    width: 45vw;
    background-color: #fff;
    border-radius: 60px;
    top: 110%;
    left: 20vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    z-index: 100;
}

.serviseBar>div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}

.serviseBar select:first-child {
    border-right: 2px solid #D9D9D9;
}


.contBoton {
    background-color: #69B32D;
    transition: background-color 0.3s;
    border-radius: 60px;
    cursor: pointer;
}

.contBoton:hover {
    background-color: #4daa00;
}

.contBoton p {
    color: #fff;
    font-weight: 600;
}

@media (max-width:800px ) and (min-width:450px) {
    .serviseBar{
        width: 74vw;
        left: 10vw;
        top: 110%;
      }
      .OptionsSelect { 
        font-size: 12px;
      }
}

@media (max-width:450px) {
    .serviseBar{
  
        width: 88vw;
        left: 6vw;
        grid-template-columns: 1fr 1fr .6fr;
        top: 110%;
      }
      .serviseBar  p{
        font-size: 12px;
      }
      .serviseBar div:nth-child(1)>p {
        width: 60%;
      }
      .serviseBar div:nth-child(2)>p {
        width: 60%;
      }
    
      .serviseBar div:nth-child(3) {
       border-radius: 35px;
      }
      
      
      .serviseBar >div > svg{
        width: 15px;
      }
      .OptionsSelect { 
        font-size: 12px;
      }
    
}