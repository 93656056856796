.botom{
    background-color: #264897;
    border: none;
    overflow: none;
    color: #fff;
    font-weight: 800;
    cursor: pointer;
}
.container{
    width: 100vw;
    min-height: 100vh;
    background-color: #fff;
    z-index: 100000000;
    padding: 70px 30px 0 30px;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container > form{
    display: flex;
    flex-direction: column;
    margin-bottom: 90px;
    position: relative;
    z-index: 100;
}

.contInput{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}
.container > form label{
    font-weight: 600;
}
.container > form input{
    width: 350px;
    height: 50px;
    margin-top: 8px;
    padding-left: 15px;
    outline: none;
    border-radius: 5px;
    border: 2px solid  #264897
}

.container > form select{
    width: 350px;
    height: 50px;
    margin-top: 8px;
    padding-left: 15px;
    background-color: #fff;
    outline: none;
    border-radius: 5px;
    border: 2px solid  #264897
}

.container > form textarea{
    background-color: #fff;
    outline: none;
    border-radius: 5px;
    border: 2px solid  #264897
}
.conLabel{
    margin-top:1px
}

.deco1{
    position: absolute;
    top: 20px;
    left: 0px;
    width: 100px;
    z-index: 10;
}

.deco1 > svg{
   width: 100vw;
}

.logo{
    position: absolute;
    top: 20px;
    left: 30px;
    width: 100px;
    z-index: 10000000000000;
}

.TextError{
    color:#cf0202;
    font-size:12px;
}


.title{
    margin-bottom: 15px;
    font-size: 25px;
}

.observaciones{
    resize: none;
    height: 150px;
    margin-top: 10px;
}


@media (max-width:400px ) {
    .container > form input{
        width: 280px;
    }
    .container > form select{
        width: 280px;
  
    }
    .title{
        margin-top: 35px;
    }
    
}