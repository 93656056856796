* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

.contWhatsBoton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100000;
  cursor: pointer;
}

.contWhatsBoton>svg:hover #circleWhats {
  fill: #008a0e;
  transition: fill 0.3s;
}

.containerHome {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 750px;

}

.conTextHome {
  padding: 110px 50px 50px 50px;
  position: relative;
}

.conTextHome>div>p {
  color: rgba(255, 255, 255, .5);
  font-size: 18px;
  margin-top: 30px;
  width: 70%;
}

.contTitle {
  width: 100%;
}

.contTitle h1 {
  font-size: 40px;
  color: #fff;
}

.ContainerViwerImage {
  width: 100%;
  min-height: 710px;
  height: 100vh;
  max-height: 900px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}


.ContainerViwerImage>div {
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
  z-index: 10;
}

.ContainerViwerImage>div>img {
  width: 65vw;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}


.ContainerViwerImage>svg {
  position: absolute;
  top: 20px;
  left: 30px;
  width: 150px;
  z-index: 1000;
}

.contOptionImage {
  position: absolute;
  bottom: 120px;
  left: 40%;
  display: flex;
  flex-direction: row;
  width: 180px;
  justify-content: space-between;
}

.containerServises {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 20px;
}

.containerServises div:first-child {
  width: 30vw;
  display: flex;
  justify-content: center;
}

.containerTitleServices {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}

.containerTitleServices>div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}


.containerTitleServices>div span {
  width: 16px;
  height: 3px;
  background-color: #69B32D;

}

.containerTitleServices>div p {

  color: #69B32D;
  font-size: 12px;
  font-weight: 700;

}

.containerTitleServices>h2 {

  font-size: 50px;
  text-align: center;

}

.contOptionImage>div {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  background-color: rgba(6, 26, 71, .64);
}

.portrait {
  background-image: url('../public/fondo.png');
  height: 90vh;
  min-height: 650px;
  width: 100%;
  background-position: -5px;
  display: flex;
  flex-direction: column;
}

.navMenu {
  margin-left: 60px;
  margin-top: 40px;
  position: relative;
}

.navMenu>ul {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
}

.navMenu>ul>li {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.svgHeader {
  width: 100vw;
  height: 100px;
  position: absolute;
  top: 0;
  left: 0
}


#logoGaleria {
  width: 200px;
}

#tipoTrasporte {
  position: relative;
  z-index: 10000;
  background-color: #fff;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

#Capa_2 {
  opacity: 0;
  display: none;
}

#Capa_3{
    position: absolute;
    right: 40px;
    top: -50px;
    width: 100px;
}

@media (max-width:980px) and (min-width:450px) {
  .portrait {
    height: 95%;
  }

  .navMenu {
    margin: 40px 15px;
  }

  .navMenu>ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navMenu>ul>li {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }

  .conTextHome {
    padding: 60px 5px 25px 45px;
  }

  .contTitle h1 {
    font-size: 30px;
  }

  .conTextHome>div>p {
    font-size: 16px;
    margin-top: 30px;
    width: 75%;
  }

  .contOptionImage {
    left: 28%;
    bottom: 70px;
  }
  #Capa_3{
    display: none;
    z-index: 100;
  }
}


@media (max-width:450px) {
  .conTextHome {
    padding: 70px 5px 25px 45px;
  }

  .contTitle h1 {
    font-size: 34px;
    margin-top: 40px;
  }

  header {
    display: flex;
    justify-content: flex-end;
  }

  .containerHome {
    grid-template-columns: 1fr;
  }

  .portrait {
    background-image: url('../public/fondo.png');
    min-height: 750px;
    height: 100vh;
    width: 100%;
    background-position: -5px;
  }

  .ContainerViwerImage {
    display: none;

  }

  .HamburgerMenu {
    display: flex;
    flex-direction: column;
    width: 34px;
    height: 30px;
    justify-content: space-between;
    margin-top: 15px;
    margin-right: 15px;
    cursor: pointer;
    position: relative;
  }

  .HamburgerMenu:hover {
    transform: scale(1.06);
  }

  .HamburgerMenu>div {
    width: 100%;
    height: 5px;
    background-color: #fff;
    border-radius: 2px;
  }

  .HamburgerMenu1 {
    display: flex;
    flex-direction: column;
    width: 34px;
    height: 30px;
    justify-content: space-between;
    margin-top: 0px;
    margin-right: 0px;
    cursor: pointer;
    position: relative;
  }

  .HamburgerMenu1:hover {
    transform: scale(1.06);
  }

  .HamburgerMenu1>div {
    width: 100%;
    height: 5px;
    background-color: #fff;
    border-radius: 2px;
  }


  .HamburgerMenuDisabled>.firtLineMenu {
    position: absolute;
    top: 0;
    transform: rotate(0);
    animation: disabledHamburgerMenuFirstLine .6s ease;
  }

  .HamburgerMenuDisabled>.SecodLineMenu {
    position: absolute;
    top: 13px;
    transform: rotate(0);
    animation: disabledHamburgerMenuSecondLine .6s ease;
  }

  .HamburgerMenuDisabled>.thirthLineMenu {
    opacity: 1;
    position: absolute;
    top: 25px;
    animation: disabledHamburgerMenuthirthLine .6s ease;
  }

  .HamburgerMenuActive>.firtLineMenu {
    top: 15px;
    position: absolute;
    transform: rotate(45deg);
    animation: activeHamburgerMenuFirstLine .6s ease;
  }

  .HamburgerMenuActive>.SecodLineMenu {
    top: 15px;
    position: absolute;
    transform: rotate(-45deg);
    animation: activeHamburgerMenuSecondLine .6s ease;
  }

  .HamburgerMenuActive>.thirthLineMenu {
    position: absolute;
    top: 25px;
    opacity: 0;
    animation: activeHamburgerMenuthirthLine .6s ease;
  }

  .navMenu {
    margin: 0;
    background-color: #69B32D;
    height: 100vh;
    width: 55vw;
    display: flex;
    position: absolute;
    top: 0;
    left: -250px;
    z-index: 1000000;
  }

  .navMenu>ul {
    display: flex;
    flex-direction: column;
    min-height: 150px;
    max-height: 240px;
    width: 100%;
    margin-top: 90px;
  }

  .navMenu>ul>li {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MenuActive {
    left: 0;
    animation: activeMenu .6s ease;
  }

  .MenuDisabled {
    left: -250px;
    animation: disableMenu .6s ease;
  }

  .svgHeader {
    opacity: 0;
  }

  #Capa_2 {
    opacity: 1;
    position: absolute;
    top: -45px;
    left: 30px;
    width: 110px;
    display: block;
  }
  #Capa_3{
    left: 20px;
  }
}

@keyframes disableMenu {
  0% {
    left: 0px;
  }

  100% {
    left: -250px;
  }
}

@keyframes activeMenu {
  0% {
    left: -250px;
  }

  100% {
    left: 0px;
  }
}

@keyframes disabledHamburgerMenuFirstLine {
  0% {
    top: 15px;
    transform: rotate(45deg);
  }

  100% {
    top: 0px;
    transform: rotate(0deg);
  }
}

@keyframes disabledHamburgerMenuSecondLine {
  0% {
    top: 15px;
    transform: rotate(-45deg);
  }

  100% {
    top: 13px;
    transform: rotate(0deg);
  }
}

@keyframes disabledHamburgerMenuthirthLine {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes activeHamburgerMenuFirstLine {
  0% {
    top: 0px;
    transform: rotate(0deg);
  }

  100% {
    top: 15px;
    transform: rotate(45deg);
  }
}

@keyframes activeHamburgerMenuSecondLine {
  0% {
    top: 13px;
    transform: rotate(0deg);
  }

  100% {
    top: 15px;
    transform: rotate(-45deg);
  }
}

@keyframes activeHamburgerMenuthirthLine {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}